import { timeDifference } from "./utils/Common.jsx";
import { Link } from "react-router-dom";
import "./List.css";

export const Listing = ({ key, listing }) => {
    return (
        <Link to={`${listing.id}`}
            className="listing"
            style={{ textDecoration: 'none' }}
            onClick={(e) => {
                //handleClick()
                window.location.href = `/${listing.id}`;
            }}
        >
            <div className="listing-img">
                <img
                    loading={key > 2 ? "lazy" : "eager"}
                    src={listing.avatar ?? "error.jpg"}
                    alt={listing.rooms}
                    className="listing-image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "error.jpg";
                    }}
                />

                <div className="listing-content">
                    <span className="offer-type">
                        {listing.isOwner ? "Собственник" : "Агентство"}
                    </span>
                    <span className="offer-time">
                        <span className="property-icon icon-timer"></span> {timeDifference(listing.lastUp)}
                    </span>

                    <span className="offer-price">
                        {listing.priceUsd > 0 ? `$${listing.priceUsd}` : `Договорная`}
                    </span>
                </div>
            </div>
            <p className="listing-address"><span className="listing-rooms">{listing.rooms === 0 ? `` : listing.rooms} ком.</span>{listing.address}</p>
        </Link>
    );
};

const List = ({ articles }) => {
    return (
        <div className="listings">
            {articles.map((listing, index) => (
                <Listing
                    key={index}
                    listing={listing}
                />
            ))}
        </div>
    );
};

export default List;
