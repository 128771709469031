import "./Filters.css";
import { rooms } from "./utils/Values.jsx"
import { cities } from "./utils/Values.jsx"


const CitySelection = ({ selectedCity, setCity }) => {
  return (
    <select onChange={(e) => setCity(e.target.value)} className="city-select">
      {
        cities.map((city) => (<option value={city} key={city} selected={selectedCity === city}>{city}</option>))
      }
    </select>
  );
};

const RoomSelection = ({ rooms, selectedRooms, toggleRoom }) => {
  return (
    <div className="room-selection">
      {rooms.map((room) => (
        <button
          key={room}
          className={`room-button ${selectedRooms?.includes(room) ? "selected" : ""
            }`}
          onClick={() => toggleRoom(room)}
        >
          {room}
        </button>
      ))}
    </div>
  );
};

const PriceInput = ({ price, setPrice, label }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]+$/.test(value)) {
      setPrice(value);
    }
  };

  return (
    <div className="price-input">
      <input
        type="text"
        value={price}
        onChange={handleChange}
        placeholder={label}
      />
      <span className="currency-symbol">$</span>
    </div>
  );
};

const OwnerSelection = ({ isOwner, setIsOwner }) => {
  return (
    <div className="room-selection">
      <button
        className={`room-button ${isOwner !== false ? "selected" : ""}`}
        onClick={() => setIsOwner(!isOwner)}
      >
        Собственник
      </button>
    </div>
  );
};

const TelegramBotButton = () => {
  return (
    <div className="room-selection">
      <button
        className={`room-button telegram bot`}
        onClick={() => { window.location.href = 'https://t.me/infoflatbot' }}
      >
        Телеграм-Бот
      </button>
    </div>
  );
};

const TelegramButton = () => {
  return (
    <div className="room-selection">
      <button
        className={`room-button telegram`}
        onClick={() => { window.location.href = 'https://t.me/infoflat' }}
      >
        Телеграм-канал
      </button>
    </div>
  );
};

const TelegramProButton = () => {
  return (
    <div className="room-selection">
      <button
        className={`room-button telegram bot`}
        onClick={() => { window.location.href = 'https://t.me/+grICQpIRRY83ODVi' }}
      >
        Infoflat PRO
      </button>
    </div>
  );
};

const TelegramChatButton = () => {
  return (
    <div className="room-selection">
      <button
        className={`room-button telegram`}
        onClick={() => { window.location.href = 'https://t.me/infoflat_board' }}
      >
        Телеграм-чат
      </button>
    </div>
  );
};

function Filters({ filters, updateFilter }) {
  const toggleRoom = (room) => {
    const res = filters.rooms?.includes(room)
      ? filters.rooms?.filter((r) => r !== room)
      : [...filters.rooms, room];
    updateFilter('rooms', res)
  };
  return (
    <div className="filters">

      <div className="filter">
        <CitySelection
          selectedCity={filters.city}
          setCity={(city) => updateFilter('city', city)}
        />
      </div>

      <div className="filter">
        <RoomSelection
          rooms={rooms}
          selectedRooms={filters.rooms}
          toggleRoom={toggleRoom}
        />
      </div>

      <div className="filter">
        <div className="price-container">
          <PriceInput price={filters.minPriceUsd} setPrice={(price) => updateFilter('minPriceUsd', price)} label="От" />
          <PriceInput price={filters.maxPriceUsd} setPrice={(price) => updateFilter('maxPriceUsd', price)} label="До" />
        </div>
      </div>

      <div className="filter">
        <OwnerSelection isOwner={filters.isOwner} setIsOwner={(isOwner) => updateFilter('isOwner', isOwner)} />
      </div>

      <div className="filter">
        <TelegramBotButton></TelegramBotButton>
      </div>

      <div className="filter">
        <TelegramButton></TelegramButton>
      </div>

      <div className="filter">
        <TelegramProButton></TelegramProButton>
      </div>


      <div className="filter">
        <TelegramChatButton></TelegramChatButton>
      </div>
    </div>
  );
}

export default Filters;
